.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header {background: #fff; min-height: 60px; display: block;-webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
margin-bottom: 30px}

  .logo {padding:7px 10px}
  .navigation {width: 70%; text-align: right; display: block;}
  .fl {float: left;}
  .fr {float: right;} 
  .btn {border-radius:2px; min-width:150px}
  label {margin-right: 10px}
  select {background: #fff; border: 1px solid #ccc; border-radius: 5px; padding:5px; min-height: 40px}
  button {opacity: 1px}
  button:hover {opacity:0.7px}
  .btn-indigo, .btn {background: #CF202E!important; border:0; color: #fff!important; border-radius: 2px; padding: 5px 35px; text-transform: capitalize; opacity:1 ; box-shadow: none}
  button:hover {cursor: pointer;}
  button .icn {position: relative; left: -7px;}