.loginWrapper .loginBox {position: relative; top:150px; float: right; padding-right:8% }
.loginWrapper .btn, .loginWrapper .inputBox, .loginWrapper select {min-width: 320px; margin:0 0 20px 0; display: block;; min-height: 40px}
.loginWrapper label {display: none;}
.loginWrapper img {margin-bottom: 20px;}
.loginWrapper .bannerBox { position: relative; background-image: url(../../img/homeBanner.jpg); 
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position-y: -50px;
  background-color: #F1F1F1;
  display: block;
  min-height: 100vh;
  width: 60%;
  float: left;
}
.loginWrapper .bannerInner {position: absolute;
  width: 400px;
  color: rgb(255, 255, 255);
  /* margin: 25% 0px 0px 15%; */
  top: 250px;
  left: 25%;}
  .loginWrapper .bannerInner h4 {font-weight: bold; text-decoration: underline}
  .loginWrapper .bannerInner p.one {font-size: 22px; margin-bottom:0}