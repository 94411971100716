.ilWrapper {
  padding-top: 80px
}

.ilWrapper .bannerBox {
  position           : relative;
  background-image   : url(../../img/img5.jpg);
  width              : 450px;
  background-position: 0 0;
  background-repeat  : no-repeat;
  background-size    : unset;
  background-color   : #fff;
  margin-left        : 20px;
  position           : fixed;
}

.header {
  position: fixed;
  top     : 0;
  height  : 60px;
  z-index : 999;
  width   : 100%
}

.ilWrapper label {
  float: left;
}

.ilWrapper .col-md-6:nth-child(3) {
  margin       : 0 auto;
  margin-bottom: 20px
}

.ilWrapper .bannerBox {
  padding-top: 230px
}

.ilWrapper .contentBox {
  text-align: left;
  color     : #112253;
  font-size : 12px;
  padding   : 20px;
  background: #F2F2F2;
  width     : 425px;
  height    : calc(100vh - 100px);
}

.ilWrapper .contentBox h4 {
  color      : #CF202E;
  font-weight: bold
}

.ilWrapper .contentBox h3 {
  font-size: 20px
}

.ilWrapper .contentBox ul {
  margin : 0;
  padding: 0 0 0 20px
}

.ilWrapper .pageTitle,
.ilWrapper .btnValidate {
  display: none;
}

.ilWrapper .pageContent {
  color : #112253;
  margin: 30px 0 0
}

.ilWrapper .formBox {
  width: 65%
}

/** buttons section **/
.ilWrapper .makeStyles-backButton-2,
.btnEdit,
.btn-box button {
  background: none;
  border    : 1px solid #777777 !important;
  padding   : 5px 30px !important;
  color     : #000
}

.ilWrapper .makeStyles-backButton-2:hover {
  color: #fff
}

.ilWrapper .buttonSet2 {
  position: relative;
  top     : -53px;
  float   : left;
  left    : 10px
}

.ilWrapper .form-actions {
  float: right;
}

.ilWrapper button {
  min-width: 120px;
}

.btnEdit:hover, .btnAdd:hover {
  background: #3f51b5;
  color     : #fff
}

.btnEdit {
  margin-bottom: 0
}

/** buttons section END **/

/** header navigation section **/
.navigation ul {
  float: right;
}

.navigation ul li {
  display     : block;
  float       : left;
  margin-right: 20px;
  position    : relative;
  padding     : 15px 10px
}

.navigation ul li>ul {
  display: none;
}

.navigation ul li.notif {
  padding: 15px 0 !important;
  margin : 0 5px
}

.navigation ul li a {
  color          : #112253;
  text-decoration: none
}

.navigation ul li a:hover {
  color: #CF202E
}

.navigation ul li:hover ul,
.navigation ul li ul li {
  display: block;
  padding: 5px
}

.navigation ul li ul {
  position     : absolute;
  top          : 60px;
  left         : -20px;
  background   : #fff;
  border-radius: 0 0 7px 7px;
  border       : 1px solid #f2f2f2;
  box-shadow   : 0 3px 5px 5px #ccc;
}

/** header navigation section END **/

.step2 .col-md-6:nth-child(1) {
  width: 30% !important
}

.step2 .col {
  padding: 0
}

.step2 .col-xs-12.col-md-12 {
  padding-left: 0;
  margin      : 0
}

.App.step3 input,
.App.step4 input {
  background   : #fff;
  border       : 1px solid #ccc;
  border-radius: 5px;
  padding      : 5px;
  min-height   : 40px;
  margin       : 0 10px 15px
}

.topSection label.fl {
  margin-top: 5px
}

.topSection select {
  min-width: 230px
}

.topSection .link {
  margin-right   : 20px;
  text-decoration: underline !important
}

.topSection .link:hover {
  text-decoration: none !important
}

.step1 label {
  float: none;
}

.custom-control-label::before {
  left: 0
}

.btn-box.buttonAdd {
  border: 1px solid #CB1F2D !important;
  color : #CB1F2D !important;
}

.btn-primary {
  background-color: #CF202E !important;
  color           : #fff !important;
}