body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.header {background: #fff; min-height: 60px; display: block;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
margin-bottom: 30px}

  .logo {padding:7px 10px}
  .navigation {width: 70%; text-align: right; display: block;}
  .fl {float: left;}
  .fr {float: right;} 
  .btn {border-radius:2px; min-width:150px}
  label {margin-right: 10px}
  select {background: #fff; border: 1px solid #ccc; border-radius: 5px; padding:5px; min-height: 40px}
  button {opacity: 1px}
  button:hover {opacity:0.7px}
  .btn-indigo, .btn {background: #CF202E!important; border:0; color: #fff!important; border-radius: 2px; padding: 5px 35px; text-transform: capitalize; opacity:1 ; box-shadow: none}
  button:hover {cursor: pointer;}
  button .icn {position: relative; left: -7px;}
.ilWrapper {
  padding-top: 80px
}

.ilWrapper .bannerBox {
  position           : relative;
  background-image   : url(/static/media/img5.946d73db.jpg);
  width              : 450px;
  background-position: 0 0;
  background-repeat  : no-repeat;
  background-size    : unset;
  background-color   : #fff;
  margin-left        : 20px;
  position           : fixed;
}

.header {
  position: fixed;
  top     : 0;
  height  : 60px;
  z-index : 999;
  width   : 100%
}

.ilWrapper label {
  float: left;
}

.ilWrapper .col-md-6:nth-child(3) {
  margin       : 0 auto;
  margin-bottom: 20px
}

.ilWrapper .bannerBox {
  padding-top: 230px
}

.ilWrapper .contentBox {
  text-align: left;
  color     : #112253;
  font-size : 12px;
  padding   : 20px;
  background: #F2F2F2;
  width     : 425px;
  height    : calc(100vh - 100px);
}

.ilWrapper .contentBox h4 {
  color      : #CF202E;
  font-weight: bold
}

.ilWrapper .contentBox h3 {
  font-size: 20px
}

.ilWrapper .contentBox ul {
  margin : 0;
  padding: 0 0 0 20px
}

.ilWrapper .pageTitle,
.ilWrapper .btnValidate {
  display: none;
}

.ilWrapper .pageContent {
  color : #112253;
  margin: 30px 0 0
}

.ilWrapper .formBox {
  width: 65%
}

/** buttons section **/
.ilWrapper .makeStyles-backButton-2,
.btnEdit,
.btn-box button {
  background: none;
  border    : 1px solid #777777 !important;
  padding   : 5px 30px !important;
  color     : #000
}

.ilWrapper .makeStyles-backButton-2:hover {
  color: #fff
}

.ilWrapper .buttonSet2 {
  position: relative;
  top     : -53px;
  float   : left;
  left    : 10px
}

.ilWrapper .form-actions {
  float: right;
}

.ilWrapper button {
  min-width: 120px;
}

.btnEdit:hover, .btnAdd:hover {
  background: #3f51b5;
  color     : #fff
}

.btnEdit {
  margin-bottom: 0
}

/** buttons section END **/

/** header navigation section **/
.navigation ul {
  float: right;
}

.navigation ul li {
  display     : block;
  float       : left;
  margin-right: 20px;
  position    : relative;
  padding     : 15px 10px
}

.navigation ul li>ul {
  display: none;
}

.navigation ul li.notif {
  padding: 15px 0 !important;
  margin : 0 5px
}

.navigation ul li a {
  color          : #112253;
  text-decoration: none
}

.navigation ul li a:hover {
  color: #CF202E
}

.navigation ul li:hover ul,
.navigation ul li ul li {
  display: block;
  padding: 5px
}

.navigation ul li ul {
  position     : absolute;
  top          : 60px;
  left         : -20px;
  background   : #fff;
  border-radius: 0 0 7px 7px;
  border       : 1px solid #f2f2f2;
  box-shadow   : 0 3px 5px 5px #ccc;
}

/** header navigation section END **/

.step2 .col-md-6:nth-child(1) {
  width: 30% !important
}

.step2 .col {
  padding: 0
}

.step2 .col-xs-12.col-md-12 {
  padding-left: 0;
  margin      : 0
}

.App.step3 input,
.App.step4 input {
  background   : #fff;
  border       : 1px solid #ccc;
  border-radius: 5px;
  padding      : 5px;
  min-height   : 40px;
  margin       : 0 10px 15px
}

.topSection label.fl {
  margin-top: 5px
}

.topSection select {
  min-width: 230px
}

.topSection .link {
  margin-right   : 20px;
  text-decoration: underline !important
}

.topSection .link:hover {
  text-decoration: none !important
}

.step1 label {
  float: none;
}

.custom-control-label::before {
  left: 0
}

.btn-box.buttonAdd {
  border: 1px solid #CB1F2D !important;
  color : #CB1F2D !important;
}

.btn-primary {
  background-color: #CF202E !important;
  color           : #fff !important;
}
.ssWrapper {
  padding-top: 80px
}

.ssWrapper .bannerBox {
  position           : relative;
  background-image   : url(/static/media/img5.946d73db.jpg);
  width              : 450px;
  background-position: 0 0;
  background-repeat  : no-repeat;
  background-size    : unset;
  background-color   : #fff;
  margin-left        : 20px;
  position           : fixed;
}

.header {
  position: fixed;
  top     : 0;
  height  : 60px;
  z-index : 999;
  width   : 100%
}

.ssWrapper label {
  float: left;
}

.ssWrapper .col-md-6:nth-child(3) {
  margin       : 0 auto;
  margin-bottom: 20px
}

.ssWrapper .bannerBox {
  padding-top: 230px
}

.ssWrapper .contentBox {
  text-align: left;
  color     : #112253;
  font-size : 12px;
  padding   : 20px;
  background: #F2F2F2;
  width     : 425px;
  height    : calc(100vh - 100px);
}

.ssWrapper .contentBox h4 {
  color      : #CF202E;
  font-weight: bold
}

.ssWrapper .contentBox h3 {
  font-size: 20px
}

.ssWrapper .contentBox ul {
  margin : 0;
  padding: 0 0 0 20px
}

.ssWrapper .pageTitle,
.ssWrapper .btnValidate {
  display: none;
}

.ssWrapper .pageContent {
  color : #112253;
  margin: 30px 0 0
}

.ssWrapper .formBox {
  width: 65%
}

/** buttons section **/
.ssWrapper .makeStyles-backButton-2,
.btnEdit,
.btn-box button {
  background: none;
  border    : 1px solid #777777 !important;
  padding   : 5px 30px !important;
  color     : #000
}

.ssWrapper .makeStyles-backButton-2:hover {
  color: #fff
}

.ssWrapper .buttonSet2 {
  position: relative;
  top     : -53px;
  float   : left;
  left    : 10px
}

.ssWrapper .form-actions {
  float: right;
}

.ssWrapper button {
  min-width: 120px;
}

.btnEdit:hover, .btnAdd:hover {
  background: #3f51b5;
  color     : #fff
}

.btnEdit {
  margin-bottom: 0
}

/** buttons section END **/

/** header navigation section **/
.navigation ul {
  float: right;
}

.navigation ul li {
  display     : block;
  float       : left;
  margin-right: 20px;
  position    : relative;
  padding     : 15px 10px
}

.navigation ul li>ul {
  display: none;
}

.navigation ul li.notif {
  padding: 15px 0 !important;
  margin : 0 5px
}

.navigation ul li a {
  color          : #112253;
  text-decoration: none
}

.navigation ul li a:hover {
  color: #CF202E
}

.navigation ul li:hover ul,
.navigation ul li ul li {
  display: block;
  padding: 5px
}

.navigation ul li ul {
  position     : absolute;
  top          : 60px;
  left         : -20px;
  background   : #fff;
  border-radius: 0 0 7px 7px;
  border       : 1px solid #f2f2f2;
  box-shadow   : 0 3px 5px 5px #ccc;
}

/** header navigation section END **/

.step2 .col-md-6:nth-child(1) {
  width: 30% !important
}

.step2 .col {
  padding: 0
}

.step2 .col-xs-12.col-md-12 {
  padding-left: 0;
  margin      : 0
}

.App.step3 input,
.App.step4 input {
  background   : #fff;
  border       : 1px solid #ccc;
  border-radius: 5px;
  padding      : 5px;
  min-height   : 40px;
  margin       : 0 10px 15px
}

.topSection label.fl {
  margin-top: 5px
}

.topSection select {
  min-width: 230px
}

.topSection .link {
  margin-right   : 20px;
  text-decoration: underline !important
}

.topSection .link:hover {
  text-decoration: none !important
}

.step1 label {
  float: none;
}

.custom-control-label::before {
  left: 0
}

.btn-primary {
  background-color: #CF202E !important;
  color           : #fff !important;
}
.homeContainer {
          padding-top: 80px
}

.homeContainer .bannerBox {
          position           : relative;
          _background-image   : url(/static/media/img1.4a25ffff.jpg);
          width              :45%;
          background-position: 0 0;
          background-repeat  : no-repeat;
          background-size    : unset;
          background-color   : #fff;
          margin-left        : 20px;
          height: 610px;
          overflow: hidden;
          
}

.header {
          position: fixed;
          top     : 0;
          height  : 60px;
          z-index : 999;
          width   : 100%
}

.homeContainer label {
          float: left;
}

.homeContainer .col-md-6:nth-child(3) {
          margin       : 0 auto;
          margin-bottom: 20px
}

.homeContainer .bannerBoxImg {
 position: relative; left: -350px; top:-170px
}

.homeContainer .contentBox {
          text-align: left;
          color     : #112253;
          font-size : 12px;
          padding   : 20px;
          background: #F2F2F2;
          width     : 425px;
          height    : calc(100vh - 100px);
}

.homeContainer .contentBox h4 {
          color      : #CF202E;
          font-weight: bold
}

.homeContainer .contentBox h3 {
          font-size: 20px
}

.homeContainer .contentBox ul {
          margin : 0;
          padding: 0 0 0 20px
}

.homeContainer .pageTitle,
.homeContainer .btnValidate {
          display: none;
}

.homeContainer .pageContent {
          color : #112253;
          margin: 30px 0
}

.homeContainer .formBox {
          width:50%; position: relative; top:100px
}

/** buttons section **/
.homeContainer .makeStyles-backButton-2,
.btnEdit,
.btn-box button {
          background: none;
          border    : 1px solid #777777 !important;
          padding   : 5px 30px !important;
}

.homeContainer .buttonSet2 {
          position: relative;
          top     : -53px;
          float   : left;
          left    : 10px
}

.homeContainer .form-actions {
          float: right;
}

.homeContainer button {
          min-width: 120px;
}

.btnEdit:hover, .btnAdd:hover {
          background: #3f51b5;
          color     : #fff
}

/** buttons section END **/

/** header navigation section **/
.navigation ul {
          float: right;
}

.navigation ul li {
          display     : block;
          float       : left;
          margin-right: 20px;
          position    : relative;
          padding     : 15px 10px
}

.navigation ul li>ul {
          display: none;
}

.navigation ul li.notif {
          padding: 15px 0 !important;
          margin : 0 5px
}

.navigation ul li a {
          color          : #112253;
          text-decoration: none
}

.navigation ul li a:hover {
          color: #CF202E
}

.navigation ul li:hover ul,
.navigation ul li ul li {
          display: block;
          padding: 5px
}

.navigation ul li ul {
          position     : absolute;
          top          : 60px;
          left         : -20px;
          background   : #fff;
          border-radius: 0 0 7px 7px;
          border       : 1px solid #f2f2f2;
          box-shadow   : 0 3px 5px 5px #ccc;
}

/** header navigation section END **/

.step2 .col-md-6:nth-child(1) {
          width: 30% !important
}

.step2 .col {
          padding: 0
}

.step2 .col-xs-12.col-md-12 {
          padding-left: 0;
          margin      : 0
}

.btnEdit {
          margin-bottom: 20px
}

.App.step3 input,
.App.step4 input {
          background   : #fff;
          border       : 1px solid #ccc;
          border-radius: 5px;
          padding      : 5px;
          min-height   : 40px;
          margin       : 0 10px 15px
}

.buyButton {
          background-color: #CF202E;
          color           : #fff;
}

.infinityClass {
          font-size  : 28px;
          font-weight: bold;
          color      : #112253;

}

.bottomText {
          text-align       : left;
          /* font-family   : "Trebuchet MS", Arial, Helvetica, sans-serif; */
          letter-spacing   : 0px;
          color            : #112253;
          opacity          : 1;
}

.pad0 {
          margin-top : 14em;
          margin-left: 46em;
}

.card-body {

          background-color: #F2F2F2;

}

.cardBox {float: left; width:320px}
.loginWrapper .loginBox {position: relative; top:150px; float: right; padding-right:8% }
.loginWrapper .btn, .loginWrapper .inputBox, .loginWrapper select {min-width: 320px; margin:0 0 20px 0; display: block;; min-height: 40px}
.loginWrapper label {display: none;}
.loginWrapper img {margin-bottom: 20px;}
.loginWrapper .bannerBox { position: relative; background-image: url(/static/media/homeBanner.f14a129d.jpg); 
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position-y: -50px;
  background-color: #F1F1F1;
  display: block;
  min-height: 100vh;
  width: 60%;
  float: left;
}
.loginWrapper .bannerInner {position: absolute;
  width: 400px;
  color: rgb(255, 255, 255);
  /* margin: 25% 0px 0px 15%; */
  top: 250px;
  left: 25%;}
  .loginWrapper .bannerInner h4 {font-weight: bold; text-decoration: underline}
  .loginWrapper .bannerInner p.one {font-size: 22px; margin-bottom:0}
.qcWrapper {padding-top:80px}
.qcWrapper .bannerBox { position: relative; background-image: url(/static/media/img5.946d73db.jpg); width: 450px; 
  background-position: 0 0; background-repeat:no-repeat; background-size: unset; 
  background-color :#fff; margin-left: 20px; position: fixed; }
  .header {position: fixed; top:0; height:60px; z-index: 999; width: 100%}
  .qcWrapper label {float: left;}
  .qcWrapper .col-md-6:nth-child(3) {margin: 0 auto; margin-bottom: 20px}
 .qcWrapper .bannerBox {padding-top:230px}
 .qcWrapper .contentBox {text-align: left; color: #112253; font-size: 12px; padding:20px; background: #F2F2F2; width: 425px;  height: calc(100vh - 100px);}
 .qcWrapper .contentBox h4 {color: #CF202E; font-weight: bold}
 .qcWrapper .contentBox h3 {font-size: 20px}
 .qcWrapper .contentBox ul {margin:0; padding: 0 0 0 20px}
 .qcWrapper .pageTitle, .qcWrapper .btnValidate {display: none;}
 .qcWrapper .pageContent {color: #112253; margin:10px 0 0}
 .qcWrapper .formBox {width: 65%}

/** buttons section **/
 .qcWrapper .makeStyles-backButton-2, .btnEdit, .btn-box button {background: none; border:1px solid #777777!important; 
  padding:5px 30px!important;}
  .qcWrapper .buttonSet2 {position: relative; top:-53px; float: left; left: 10px}
  .qcWrapper .form-actions {float: right;}
  .qcWrapper button {min-width: 120px; margin-bottom: 20px}
  .btnEdit:hover, .btnAdd:hover {background: #3f51b5; color: #fff}
/** buttons section END **/

/** header navigation section **/
  .navigation ul {float: right;}
  .navigation ul li {display: block; float: left; margin-right:20px; position: relative; padding:15px 10px}
  .navigation ul li > ul {display: none;}
  .navigation ul li.notif {padding:15px 0!important; margin:0 5px}
  .navigation ul li a {color:#112253; text-decoration: none}
  .navigation ul li a:hover {color: #CF202E}
  .navigation ul li:hover ul, .navigation ul li ul li {display:block; padding: 5px}
  .navigation ul li ul {position: absolute; top:60px; left:-20px; background: #fff; 
    border-radius: 0 0 7px 7px; border:1px solid #f2f2f2; box-shadow: 0 3px 5px 5px #ccc; }
  /** header navigation section END **/

  .step2 .col-md-6:nth-child(1) {width: 30%!important}
  .step2 .col {padding: 0}
  .step2 .col-xs-12.col-md-12 {padding-left: 0; margin: 0}

  .btnEdit {margin-bottom:0}

  .App.step3 input, .App.step4 input {background: #fff; border: 1px solid #ccc; border-radius: 5px; padding:5px; min-height: 40px; margin:0 10px 15px}
  .step1 label {float: none;}

  .btnSave, .btnValidate {position: relative; top:-50px; float: right;}
  .container.form.step1 .form-actions  {position: relative;  left: -140px; top: 15px;}
  .step3 .form-actions  {position: relative;  left: -140px; top:15px;}
  .step2 .form-actions  {position: relative;  left: -140px; top:15px;}
  .step4 .form-actions  {position: relative;  left: -140px; top:15px;}
